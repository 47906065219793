<template>
  <div>
    <div class="text_right switchList">
<!--      <el-button icon="el-icon-download" :disabled="loading" size="small" @click="downloadData()">批量下载</el-button>-->
      <div class="quehuan">
        <el-button :disabled="loading" :type="listType ? '' : 'primary'" @click="listType = false" class="buttineq1" size="small">卡片</el-button>
        <el-button :disabled="loading" :type="listType ? 'primary' : ''" @click="listType = true" class="buttineq2" size="small">列表</el-button>
      </div>
    </div>
    <el-table
        v-show="listType"
        v-loading="loading"
        height="calc(100vh - 340px)"
        :data="threeDataList"
        size="small"
        ref="multipleTable"
        class="table"
        row-key="id"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="url" label="文件预览" width="120px">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              :src="require('@/assets/img/collThree.png')"
              :fit="'fill'"
              @click="threeFile(scope.row.fileThreeVO)"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="modelTypeName" label="数据分级" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200px"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="text"
                     @click="downloadData(scope.row)">
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listType" v-loading="loading">
      <el-row :gutter="10" style="height: calc(100vh - 330px);overflow-y: auto;width: 100%">
        <div v-if="threeDataList.length">
          <el-col :span="4" v-for="item in threeDataList" :key="item.id">
            <el-card
                :class="['box-card',dataListSelect.filter(item2=>item2.id == item.id).length == 1 ? 'selectDa' : '']">
              <div @click="selectData(item)" class="xhuanze">
                <el-image
                    style="width: 100%; height: 150px"
                    :src="require('@/assets/img/collThree.png')"
                    :fit="'fill'"
                ></el-image>
                <div class="leftTopType">{{ item.modelTypeName }}
                </div>
                <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                  <div class="omit fileName">{{ item.fileName }}</div>
                </el-tooltip>
              </div>
              <div class="flex_b_c" style="padding-top: 10px">
                <el-button size="mini" type="text"
                           @click="threeFile(item.fileThreeVO)">
                  查看
                </el-button>
                <el-button size="mini" type="text"
                           @click="downloadData(item)">
                  下载
                </el-button>
              </div>
            </el-card>
          </el-col>
        </div>
        <div class="noAccessory" v-else>
          <img src="@/assets/img/noData.png">
          <div>暂无相关数据</div>
        </div>
      </el-row>
    </div>
<!--    <div class="text_center">-->
<!--      <el-pagination-->
<!--          @size-change="sizeChangeHandle"-->
<!--          @current-change="currentChangeHandle"-->
<!--          :current-page="current"-->
<!--          :page-sizes="[10, 20, 50, 100]"-->
<!--          :page-size="size"-->
<!--          :total="total"-->
<!--          background-->
<!--          layout="total, sizes, prev, pager, next, jumper"-->
<!--      >-->
<!--      </el-pagination>-->
<!--    </div>-->
    <see-three-file ref="seethree"></see-three-file>
  </div>
</template>

<script>
import SeeThreeFile from "@/views/modules/collection/accounts/module/seeThreeFile.vue";


export default {
  name: "threes",
  components: {SeeThreeFile},
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },

    //typePage：审核页面的标识(通过和驳回时用)
    dir: {
      type: String,
      default: '',
    },

    //默认文献资料，appraisal鉴定资料
    type: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      listType: false,
      loading:false,
      threeDataList: [],
      dataListSelect: [],
      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.getPicture()
  },

  methods: {
    getPicture(dividePage) {
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.collection.listThreeFileByDataId, {
        dataId: this.id,
        current:this.current,
        size:this.size,
      }, 'get').then((data) => {
        if (data.status) {
          this.loading = false
          this.threeDataList = data.data
          this.threeDataList.forEach((item,index)=>{
            this.$set(item,'id',item.folderOnlyId)
          })
          // this.total = parseInt(data.data.total)
        }
      })
    },

    threeFile(threedate) {
      threedate.img.forEach(item=>{
        this.$set(item,'url',item.netUrl)
      })
      this.$refs.seethree.init(threedate)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //列表选中
    selectData(item) {
      let data = this.dataListSelect.filter(item2 => {
        return item2.folderOnlyId == item.folderOnlyId
      })
      if (data.length == 1) {
        this.$refs.multipleTable.toggleRowSelection(item, false);
      } else {
        this.$refs.multipleTable.toggleRowSelection(item);
      }
    },

    //下载
    downloadData(row){
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = []
      if (row){
        ids = [row.folderOnlyId]
      }else {
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.folderOnlyId
          }
        })
      }
      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.collection.urlThreeZip, {
        ids,
      }, 'get','blob').then((res) => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        let filename = '三维模型';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },


    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1
      this.getPicture();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getPicture();
    },
  },
}
</script>

<style scoped>
.switchList {
  display: flex;
  justify-content: right;
  align-items: center;
  .quehuan{
    margin-left: 15px;
    .buttineq1{
      border-radius: 3px 0 0 3px;
    }
    .buttineq2{
      border-radius: 0 3px 3px 0;
      margin: 0 !important;
    }
  }
}

.xhuanze{
  position: relative;
  .fileName{
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    height: 20px;
    color: #FFFFFF;
    background: rgba(0,0,0,.5);
  }
}

.box-card {
  margin-bottom: 10px;

  &::v-deep .el-card__body {
    padding: 10px !important;
  }
}

.noAccessory {
  text-align: center;

  img {
    width: 20%;
  }
}

.selectDa {
  border-color: #77B5FE;
  background: rgba(119, 181, 254, .05);
}

.leftTopType{
  position: absolute;
  top: 15px;
  left: 0;
  width: 50px;
  text-align: center;
  padding: 2px 5px;
  border-radius: 0 10px 10px 0;
  font-size: 10px;
  color: #ffffff;
  background: #BC2124;
}
</style>