<template>
  <div>
    <el-form class="query-form searchForm"
             @keyup.enter.native="getDataList(1,1)"
             inline size="small" ref="inputForm" :model="inputForm" label-width="80px">
      <el-form-item label="事件名称" prop="protectEventName">
        <el-input v-model.trim="inputForm.protectEventName"
                  placeholder="请输入事件名称(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="保护人员" prop="protectionPersonnel">
        <el-input v-model.trim="inputForm.protectionPersonnel"
                  placeholder="请输入保护人员(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="保护设备" prop="protectiveEquipment">
        <el-input v-model.trim="inputForm.protectiveEquipment"
                  placeholder="请输入保护设备(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="保护类型" prop="protectionType">
        <el-select
            v-model="inputForm.protectionType"
            clearable
            placeholder="请选择保护类型"
            style="width: 100%"
        >
          <el-option
              v-for="item in this.$dictUtils.getDictList('protection_type')"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="保护时间" prop="appraisalDate" class="dateBox2">
        <el-date-picker
            v-model="inputForm.appraisalDate"
            type="daterange"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询
        </el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
      </el-form-item>
      <el-form-item class="f_r">
        <el-button size="small"
                   @click="exportData(1)">
          <i class="icon-piliangdaochu iconfont buIcon"/>
          批量导出
        </el-button>
        <el-button icon="el-icon-download" size="small" @click="downloadData()">
          批量下载
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        ref="multipleTable"
        height="calc(100vh - 430px)"
        class="table"
        :row-key="'id'"
        :header-cell-style="{background:'#F9DFDF'}"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="protectEventName" label="保护事件名称" show-overflow-tooltip width="100"></el-table-column>
      <el-table-column prop="protectionType" label="保护类型" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ $dictUtils.getDictLabel("protection_type", scope.row.protectionType, '-') }}
        </template>
      </el-table-column>
      <el-table-column prop="mountStartTime" label="保护时间" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.cycleStart + '-' + scope.row.cycleEnd }}
        </template>
      </el-table-column>
      <el-table-column prop="protectionPersonnel" label="保护人员" show-overflow-tooltip></el-table-column>
      <el-table-column prop="protectiveEquipment" label="保护设备" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createUserName" label="创建人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createDate" label="创建时间" width="150px">
        <template slot-scope="scope">
          {{ scope.row.createDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column prop="submitUserName" label="最后更新人"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateDate" label="最后更新时间" width="150px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.updateDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" type="text"
                     @click="addData(1, scope.row,scope.$index)">
            详情
          </el-button>
          <el-button size="mini" type="text"
                     @click="downloadData(scope.row)">
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "protect",
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },
    selectTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputForm: {
        protectEventName: '',
        protectionPersonnel: '',
        protectiveEquipment: '',
        protectionType: '',
        appraisalDate: [],
        cycleStart: '',
        cycleEnd: '',
      },
      dataListSelect: [],
      loading: false,
      dataList: [],

      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.getDataList()
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      if (this.inputForm.appraisalDate && this.inputForm.appraisalDate.length != 0) {
        this.inputForm.cycleStart = this.inputForm.appraisalDate[0]
        this.inputForm.cycleEnd = this.inputForm.appraisalDate[1]
      } else {
        this.inputForm.cycleStart = ''
        this.inputForm.cycleEnd = ''
      }
      this.searchRecord = {
        ...this.inputForm,
        collectionId:this.id,
        processState: ["3"],
        current: this.current,
        size: this.size,
      }
      let fechUrl = this.api.collection.collectionProtectionList
      this.$axios(fechUrl, this.searchRecord, 'post').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })
      let data = {
        ids: ids,
      }
      this.exportExcel(this.api.collection.collectionProtectionBatchExport, data, '保护情况列表', 'post')
      this.getDataList('', 1)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //下载
    downloadData(row) {
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = []
      if (row) {
        if(row.fileCount){
          ids = [
            {
              repairId: row.id,
              xuhao: row.number,
              dataId: this.id,
            }
          ]
        }
      } else {
        this.dataListSelect.forEach(item => {
          if (item) {
            if (item.fileCount){
              ids.push(
                  {
                    repairId: item.id,
                    xuhao: item.number,
                    dataId: this.id,
                  }
              )
            }
          }
        })
      }
      if (ids.length == 0){
        this.$message.error('暂无附件！')
        return
      }
      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.collection.collectionProtectionBatchDownload, ids, 'post', 'blob').then((res) => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        let filename = '保护资料';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
      this.getDataList('', 1)
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', '');
    },

    //num 1详情
    addData(num, row) {
      let listSearch = {
        inputForm: this.inputForm,
        selectTab: this.selectTab,
        current: this.current,
        size: this.size
      }
      sessionStorage.setItem('listSearchCollDetail', JSON.stringify(listSearch))
      let routerUrl = {
        path: '/collection/business/protect/addProtect',
        query: {butType: 5, id: row.id,dataId:this.id},
      }
      this.$emit('gotoOtherDetail', routerUrl)
    },
  },
}
</script>

<style scoped>

</style>